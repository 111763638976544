<template>
  <div class="about">
    <h1>{{ $t("proxyPackages.title") }}</h1>
    <div class="d-flex justify-space-between">
      <div class="pt-4 font-weight-bold">
        <v-btn text large color="primary" @click="filterDialog = true">
          {{ $t("filters") }}
          <v-icon small class="ml-2">mdi-filter-menu-outline</v-icon>
        </v-btn>
        <v-btn
          v-if="hasAppliedFilters"
          outlined
          x-small
          color="red"
          class="ml-2"
          @click="resetFilters"
        >
          {{ $t("resetFilters") }}
        </v-btn>
      </div>
      <div class="d-flex">
        <v-select
          item-text="label"
          item-value="attribute"
          :items="attributes"
          label="Order by"
          v-model="sort.sortBy"
          style="max-width: 180px"
        ></v-select>
        <span v-if="sort.sortBy" class="mt-5 ml-2">
          <v-icon v-if="sort.desc" @click="sort.desc = false">
            mdi-sort-descending
          </v-icon>
          <v-icon v-if="!sort.desc" @click="sort.desc = true">
            mdi-sort-ascending
          </v-icon>
        </span>
      </div>
    </div>
    <div v-if="hasAppliedFilters">
      <v-chip
        close
        color="primary lighten-1"
        v-for="(filter, label) in formattedFilters"
        :key="label"
        class="mr-1 mb-2"
        @click:close="resetFilter(label)"
      >
        {{ attributeLabels[label] }}: {{ filter }}
      </v-chip>
    </div>
    <div class="text-right">
      <span v-if="totalCount > 0" class="ml-2 body-2"
        >Showing {{ offset + 1 }}-{{ maxItem }} of {{ totalCount }} items</span
      >
    </div>
    <div>
      <v-overlay
        :absolute="true"
        :value="dataLoading"
        justify-center
        align-center
        opacity="0.15"
      >
        <v-progress-circular size="64" indeterminate></v-progress-circular>
      </v-overlay>
      <div v-if="!dataLoading">
        <!--    Data table     -->
        <v-data-iterator
          hide-default-footer
          disable-filtering
          disable-pagination
          disable-sort
          :items="items"
        >
          <template v-slot:header>
            <div class="d-sm-none d-md-none d-lg-block px-4 py-0 pb-0">
              <v-row class="grey--text body-2">
                <v-col cols="2" lg="2" class="align-self-end">
                  {{ $t("proxyPackages.attribute.title") }}
                </v-col>
                <v-col cols="1" lg="1" class="align-self-end">
                  {{ $t("proxyPackages.attribute.description") }}
                </v-col>
                <v-col cols="1" lg="1" class="align-self-end">
                  {{ $t("proxyPackages.attribute.price") }}
                </v-col>
                <v-col cols="1" lg="1" class="align-self-end">
                  {{ $t("proxyPackages.attribute.proxiesAmount") }}
                </v-col>
                <v-col cols="1" lg="1" class="align-self-end">
                  {{ $t("proxyPackages.attribute.statistics") }}
                </v-col>
                <v-col cols="2" lg="2" class="align-self-end">
                  {{ $t("proxyPackages.attribute.source") }}
                </v-col>
                <v-col cols="1" lg="1" class="align-self-end">
                  {{ $t("proxyPackages.attribute.status") }}
                </v-col>
                <v-col cols="2" lg="2" class="align-self-end">
                  {{ $t("proxyPackages.attribute.expiredAt") }}
                </v-col>
              </v-row>
            </div>
          </template>
          <template v-slot:default="props">
            <proxy-packages-item
              v-for="(item, i) in props.items"
              :key="i"
              :item="item"
              @editInfo="editItem(item)"
              @viewInfo="viewItem(item)"
              @syncItem="confirmSync(item)"
              @activateItem="confirmActivation(item)"
            ></proxy-packages-item>
          </template>
        </v-data-iterator>
        <div class="text-right">
          <span v-if="totalCount > 0" class="ml-2 body-2"
            >Showing {{ offset + 1 }}-{{ maxItem }} of
            {{ totalCount }} items</span
          >
        </div>
      </div>
    </div>
    <!--    Pagination    -->
    <div v-if="pageCount > 1" class="text-center pt-6">
      <v-pagination
        v-model="pagination.page"
        :length="pageCount"
        :total-visible="9"
      ></v-pagination>
    </div>
    <!--    Wrapper for  edit dialog   -->
    <v-dialog v-model="filterDialog" max-width="900px">
      <proxy-packages-search
        v-if="filterDialog"
        :labels="attributeLabels"
        @close="filterDialog = false"
        @applyFilters="applyFilters"
      ></proxy-packages-search>
    </v-dialog>
    <v-dialog v-model="editDialog" max-width="750px">
      <proxy-package-form
        :initialItem="selectedItem"
        @close="closeEditDialog"
        @submitted="
          loadItems();
          closeEditDialog();
        "
      ></proxy-package-form>
    </v-dialog>
    <v-dialog v-model="viewDialog" max-width="600px">
      <proxy-package-view
        :initialItem="selectedItem"
        @close="closeViewDialog"
      ></proxy-package-view>
    </v-dialog>
    <v-dialog v-if="selectedItem" v-model="confirmDialog" max-width="450px">
      <v-card>
        <v-card-title
          class="headline primary lighten-1 white--text justify-space-between"
        >
          Sync confirmation
          <v-btn icon color="white" @click="confirmDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="mt-2">
          Are you sure you want to sync {{ selectedItem.title }}?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="confirmDialog = false">Close</v-btn>
          <v-btn text color="blue darken-1" @click="syncItem(selectedItem)">
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="selectedItem"
      v-model="activateConfirmDialog"
      max-width="450px"
    >
      <v-card>
        <v-card-title
          class="headline primary lighten-1 white--text justify-space-between"
        >
          Activation confirmation
          <v-btn icon color="white" @click="activateConfirmDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="mt-2">
          Are you sure you want to activate {{ selectedItem.title }}?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="activateConfirmDialog = false"
            >Close</v-btn
          >
          <v-btn text color="blue darken-1" @click="activateItem(selectedItem)">
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  FETCH_PACKAGES,
  FETCH_SOURCE_META,
  SYNC_PACKAGE,
  SET_FILTER,
  ACTIVATE_PACKAGE,
} from "@/store/actions/proxy-package";

import ProxyPackageView from "@/views/proxy-packages/ProxyPackageView";
import ProxyPackageForm from "@/views/proxy-packages/ProxyPackageForm";
import ProxyPackagesSearch from "@/views/proxy-packages/list/ProxyPackagesSearch";
import ProxyPackagesItem from "@/views/proxy-packages/list/ProxyPackagesItem";

import _ from "lodash";
import { emptyFilters } from "@/store/modules/proxy-package/state";
import { mapGetters, mapState } from "vuex";

export default {
  name: "ProxyPackagesList",
  components: {
    ProxyPackageView,
    ProxyPackageForm,
    ProxyPackagesSearch,
    ProxyPackagesItem,
  },
  data() {
    return {
      editDialog: false,
      viewDialog: false,
      filterDialog: false,
      confirmDialog: false,
      activateConfirmDialog: false,
      dataLoading: true,
      attributes: [
        {
          label: this.$t("proxyPackages.attribute.title"),
          attribute: "title",
        },
        {
          label: this.$t("proxyPackages.attribute.description"),
          attribute: "description",
        },
        {
          label: this.$t("proxyPackages.attribute.price"),
          attribute: "price",
        },
        {
          label: this.$t("proxyPackages.attribute.proxiesAmount"),
          attribute: "proxiesAmount",
        },
        {
          label: this.$t("proxyPackages.attribute.source"),
          attribute: "source",
        },
        {
          label: this.$t("proxyPackages.attribute.status"),
          attribute: "status",
        },
        {
          label: this.$t("proxyPackages.attribute.expiredAt"),
          attribute: "expiredAt",
        },
      ],
      isSourceLoading: false,
      sourceSearch: "",
      sources: [],
      attributeLabels: {
        id: this.$t("proxyPackages.attribute.id"),
        title: this.$t("proxyPackages.attribute.title"),
        description: this.$t("proxyPackages.attribute.description"),
        currency: this.$t("proxyPackages.attribute.currency"),
        source: this.$t("proxyPackages.attribute.source"),
        status: this.$t("proxyPackages.attribute.status"),
        expiredAt: this.$t("proxyPackages.attribute.expiredAt"),
        proxiesAmount: this.$t("proxyPackages.attribute.proxiesAmount"),
        login: this.$t("proxyPackages.attribute.login"),
        password: this.$t("proxyPackages.attribute.password"),
      },
      selectedItem: null,
    };
  },
  computed: {
    ...mapState("proxyPackage", ["pagination", "filters", "sort"]),
    ...mapGetters("proxyPackage", [
      "offset",
      "itemsPerPage",
      "totalCount",
      "items",
      "pageCount",
      "hasAppliedFilters",
      "formattedFilters",
      "filters",
    ]),
    maxItem: function () {
      return Math.min(this.totalCount, this.offset + this.itemsPerPage);
    },
  },
  watch: {
    sort: {
      handler: function () {
        this.loadItems();
      },
      deep: true,
    },
    pagination: {
      handler: function () {
        this.loadItems();
      },
      deep: true,
    },
    filters: {
      handler: function () {
        this.$router.push({
          name: "ProxyPackageList",
          query: this.formattedFilters,
        });
        this.loadItems();
      },
      deep: true,
    },
    sourceSearch: function (value) {
      this.isSourceLoading = true;
      this.$store
        .dispatch("proxyPackage/" + FETCH_SOURCE_META, { source: value })
        .then(
          (payload) => {
            this.isSourceLoading = false;
            this.sources = payload.data;
          },
          (err) => {
            console.log("Err", err);
          }
        )
        .catch(() => {});
    },
  },
  methods: {
    loadItems: function () {
      this.dataLoading = true;
      this.$store
        .dispatch("proxyPackage/" + FETCH_PACKAGES)
        .then(
          () => {
            this.dataLoading = false;
          },
          (err) => {
            console.log("Err", err);
          }
        )
        .catch(() => {});
    },
    confirmSync: function (item) {
      this.selectedItem = item;
      this.confirmDialog = true;
    },
    confirmActivation: function (item) {
      this.selectedItem = item;
      this.activateConfirmDialog = true;
    },
    syncItem: function (proxyPackage) {
      this.confirmDialog = false;
      this.$store
        .dispatch("proxyPackage/" + SYNC_PACKAGE, { proxyPackage })
        .then(
          (response) => {
            if (response.status === true) {
              this.$notify({
                group: "messages",
                title: "Sync proxy package",
                text: "Synchronization task successfully submitted",
              });
            }
          },
          (err) => {
            console.log("Err", err);
          }
        )
        .catch(() => {});
    },
    activateItem: function (proxyPackage) {
      this.activateConfirmDialog = false;
      this.$store
        .dispatch("proxyPackage/" + ACTIVATE_PACKAGE, { proxyPackage })
        .then(
          (response) => {
            if (response.status === true) {
              this.$notify({
                group: "messages",
                title: "Activate proxy package",
                text: "Proxy package successfully activated",
              });
              this.loadItems();
            }
          },
          (err) => {
            console.log("Err", err);
          }
        )
        .catch(() => {});
    },
    editItem: function (item) {
      this.selectedItem = item;
      this.editDialog = 1;
    },
    viewItem: function (item) {
      this.selectedItem = item;
      this.viewDialog = 1;
    },
    closeViewDialog: function () {
      this.selectedItem = null;
      this.viewDialog = 0;
    },
    closeEditDialog: function () {
      this.selectedItem = null;
      this.editDialog = 0;
    },
    resetFilters: function () {
      this.$store.commit("proxyPackage/" + SET_FILTER, this.emptyFilters);
    },
    resetFilter: function (filterName) {
      let newFilters = _.merge({}, this.filters);
      newFilters[filterName] = _.get(emptyFilters, filterName, null);
      this.$store.commit("proxyPackage/" + SET_FILTER, newFilters);
    },
    applyFilters: function (data) {
      this.$store.commit("proxyPackage/" + SET_FILTER, data.filters);
      this.filterDialog = false;
    },
  },
  mounted() {
    if (!_.isEmpty(this.$route.query)) {
      let filters = {};
      _.forIn(this.$route.query, function (value, key) {
        if (_.has(emptyFilters, key)) {
          filters[key] = value;
        }
      });
      let packageId = this.$route.query.packageId;
      if (packageId) {
        filters.id = packageId;
      }
      this.$store.commit(
        "proxyPackage/" + SET_FILTER,
        _.merge({}, this.emptyFilters, filters)
      );
    }
    this.loadItems();
  },
};
</script>
