<template>
  <v-sheet outlined class="mt-1" :color="itemClass">
    <v-card-text class="pa-2 pl-4">
      <v-row>
        <v-col sm="4" md="4" lg="2" class="d-flex flex-nowrap text-truncate">
          {{ proxyPackage.title }}
        </v-col>
        <v-col sm="8" md="8" lg="1" class="d-flex flex-nowrap text-truncate">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div
                class="d-inline-block text-truncate"
                style="max-width: 100%"
                v-bind="attrs"
                v-on="on"
              >
                {{ proxyPackage.description }}
              </div>
            </template>
            <span>{{ proxyPackage.description }}</span>
          </v-tooltip>
        </v-col>
        <v-col sm="4" md="2" lg="1">
          <span v-if="proxyPackage.price">
            {{ proxyPackage.price }} {{ proxyPackage.currency }}
          </span>
          <span v-if="!proxyPackage.price" class="d-lg-none grey--text caption">
            Price not set
          </span>
        </v-col>

        <v-col sm="4" md="1" lg="1" class="d-flex flex-nowrap text-truncate">
          <router-link
            v-if="proxyPackage.proxiesAmount"
            :to="{
              name: 'ProxyList',
              query: { proxyPackageId: item.id },
            }"
            class="grey--text text--darken-4 text-decoration-none"
          >
            <v-icon small class="mr-1">mdi-arrow-decision-outline</v-icon>
            {{ proxyPackage.proxiesAmount }}
          </router-link>
        </v-col>
        <v-col sm="3" md="3" lg="1">
          <div v-for="(statisticItem, i) in proxyPackage.statistics" :key="i">
            <router-link
              :to="{
                name: 'AccountList',
                query: {
                  proxyPackages: [statisticItem.proxyPackageId],
                  target: [statisticItem.name],
                  selectedFilters: ['proxyPackages', 'target'],
                },
              }"
              class="text-decoration-none grey--text text--darken-4"
            >
              <v-icon
                v-text="
                  'mdi-' +
                  (statisticItem.name === 'vkontakte'
                    ? 'alpha-b-box'
                    : statisticItem.name)
                "
              ></v-icon>
              <span v-text="statisticItem.value" class="pl-1"></span>
            </router-link>
          </div>
        </v-col>
        <v-col sm="4" md="2" lg="2" class="d-flex flex-nowrap">
          {{ proxyPackage.source }}
        </v-col>
        <v-col sm="4" md="2" lg="1" class="d-flex flex-nowrap text-truncate">
          <v-chip
            :color="getColorByStatus({ status: proxyPackage.status })"
            outlined
            small
            dark
          >
            {{ proxyPackage.status }}
          </v-chip>
        </v-col>
        <v-col sm="5" md="3" lg="2" class="d-flex flex-nowrap text-truncate">
          {{ proxyPackage.expiredAt }}
          <span
            v-if="!proxyPackage.expiredAt"
            class="d-lg-none grey--text caption"
          >
            Date of expiration not set
          </span>
        </v-col>
        <v-col sm="3" md="2" lg="1" class="text-end">
          <v-btn
            v-if="proxyPackage.status === 'inactive'"
            x-small
            icon
            color="primary"
            @click="$emit('activateItem')"
          >
            <v-icon>mdi-play-speed</v-icon>
          </v-btn>
          <v-btn x-small icon color="primary" @click="$emit('editInfo')">
            <v-icon>mdi-pencil-outline</v-icon>
          </v-btn>
          <v-btn x-small icon color="primary" @click="$emit('viewInfo')">
            <v-icon>mdi-eye-outline</v-icon>
          </v-btn>
          <v-btn x-small icon color="primary" @click="$emit('syncItem')">
            <v-icon>mdi-sync</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-sheet>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  name: "ProxyPackagesItem",
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      proxyPackage: this.item,
    };
  },
  computed: {
    ...mapGetters("proxyPackage", ["getColorByStatus"]),
    tillExpiration: function () {
      return moment(this.proxyPackage.expiredAt).diff(moment(), "days");
    },
    itemClass: function () {
      return this.tillExpiration < 1
        ? " red lighten-4"
        : this.tillExpiration <= 5
        ? " deep-orange lighten-5"
        : "";
    },
  },
  methods: {},
};
</script>
