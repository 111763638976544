<template>
  <v-card>
    <v-card-title
      v-if="item"
      class="headline primary lighten-1 white--text justify-space-between"
    >
      ID {{ item.id }}: {{ item.title }}
      <v-btn icon color="white" @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <div class="d-flex justify-center pa-10" v-if="dataLoading">
        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
      <div v-if="!dataLoading" class="pa-3 pt-4">
        <v-row>
          <v-col cols="7" sm="7" md="7">
            <v-text-field
              name="title"
              :label="$t('proxyPackages.attribute.title')"
              :error-messages="packageErrors.title"
              v-model="proxyPackage.title"
              type="text"
            ></v-text-field>
            <v-text-field
              name="source"
              :label="$t('proxyPackages.attribute.source')"
              v-model="proxyPackage.source"
              :error-messages="packageErrors.source"
              type="text"
            ></v-text-field>
            <v-text-field
              name="login"
              :label="$t('proxyPackages.attribute.login')"
              v-model="proxyPackage.login"
              :error-messages="packageErrors.login"
              type="text"
            ></v-text-field>
            <v-text-field
              name="password"
              :label="$t('proxyPackages.attribute.password')"
              v-model="proxyPackage.password"
              :error-messages="packageErrors.password"
              type="text"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-row no-gutters>
              <v-col cols="6">
                <v-text-field
                  name="price"
                  prepend-icon="mdi-currency-usd"
                  :label="$t('proxyPackages.attribute.price')"
                  v-model="proxyPackage.price"
                  :error-messages="packageErrors.price"
                  type="text"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-select
                  :clearable="true"
                  name="currency"
                  :label="$t('proxyPackages.attribute.currency')"
                  v-model="proxyPackage.currency"
                  :error-messages="packageErrors.currency"
                  :items="currencies"
                ></v-select>
              </v-col>
            </v-row>
            <!--            <v-menu-->
            <!--              v-model="modal"-->
            <!--              :close-on-content-click="false"-->
            <!--              :nudge-right="40"-->
            <!--              transition="scale-transition"-->
            <!--              offset-y-->
            <!--              min-width="auto"-->
            <!--            >-->
            <!--              <template v-slot:activator="{ on, attrs }">-->
            <!--                <v-text-field-->
            <!--                  :clearable="true"-->
            <!--                  v-model="proxyPackage.expiredAt"-->
            <!--                  :error-messages="packageErrors.expiredAt"-->
            <!--                  :label="$t('proxyPackages.attribute.expiredAt')"-->
            <!--                  prepend-icon="mdi-calendar"-->
            <!--                  readonly-->
            <!--                  v-bind="attrs"-->
            <!--                  v-on="on"-->
            <!--                ></v-text-field>-->
            <!--              </template>-->
            <!--              <v-date-picker-->
            <!--                no-title-->
            <!--                v-model="proxyPackage.expiredAt"-->
            <!--                @input="modal = false"-->
            <!--              ></v-date-picker>-->
            <!--            </v-menu>-->
            <datetime-picker
              v-model="proxyPackage.expiredAt"
              :error-messages="packageErrors.expiredAt"
              :label="$t('proxyPackages.attribute.expiredAt')"
            ></datetime-picker>
          </v-col>
        </v-row>
        <v-textarea
          name="description"
          v-model="proxyPackage.description"
          :error-messages="packageErrors.description"
          auto-grow
          :label="attributeLabels.description"
        ></v-textarea>
      </div>
    </v-card-text>
    <v-card-actions v-if="!dataLoading" class="pa-4 pl-8">
      <v-spacer></v-spacer>
      <v-btn color="grey" text @click="$emit('close')">Close</v-btn>
      <v-btn color="blue darken-1" text @click="updateItem">Save</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { FETCH_PACKAGE, PATCH_PACKAGE } from "@/store/actions/proxy-package";
import _ from "lodash";

import { required, maxLength, decimal } from "vuelidate/lib/validators";
import validator from "@/mixin/validator";

import DatetimePicker from "@/views/components/DatetimePicker";

const defaultValues = {
  title: "",
  description: "",
  source: "",
  login: "",
  password: "",
  price: "",
  currency: "",
  expiredAt: "",
};

export default {
  name: "ProxyPackageForm",
  mixins: [validator],
  components: { DatetimePicker },
  data() {
    return {
      modal: 0,
      dataLoading: true,
      attributeLabels: {
        title: this.$t("proxyPackages.attribute.title"),
        description: this.$t("proxyPackages.attribute.description"),
      },
      currencies: ["USD", "EUR", "UAH", "RUB"],
    };
  },
  props: {
    initialItem: Object,
  },
  computed: {
    item: function () {
      return this.initialItem;
    },
    itemDetails: function () {
      return this.item
        ? _.get(
            this.$store.getters["proxyPackage/itemsDetails"],
            "ID_" + this.item.id,
            defaultValues
          )
        : defaultValues;
    },
    proxyPackage: {
      get() {
        return this.itemDetails;
      },
      set: function (value) {
        this.$store.commit("proxyPackage/" + FETCH_PACKAGE, value);
      },
    },
    packageErrors: function () {
      return _.merge(
        {
          title: "",
          description: "",
          source: "",
          login: "",
          password: "",
          price: "",
          currency: "",
          expiredAt: "",
        },
        this.validator.errors.proxyPackage
      );
    },
  },
  watch: {
    item: {
      handler: function () {
        if (this.item) {
          this.loadItem();
        }
      },
      deep: true,
    },
  },
  methods: {
    loadItem: function () {
      this.dataLoading = true;
      let component = component;
      const { item } = this;
      this.$store
        .dispatch("proxyPackage/" + FETCH_PACKAGE, { item })
        .then(
          () => {
            this.dataLoading = false;
          },
          (err) => {
            console.log("Err", err);
          }
        )
        .catch(() => {});
    },
    updateItem: function () {
      if (this.validate()) {
        this.dataLoading = true;
        const { proxyPackage } = this;
        this.$store
          .dispatch("proxyPackage/" + PATCH_PACKAGE, { proxyPackage })
          .then(
            (payload) => {
              this.dataLoading = false;
              if (payload.status) {
                this.$emit("submitted");
              }
            },
            (err) => {
              console.log("Err", err);
            }
          )
          .catch(() => {});
      }
    },
  },
  mounted() {
    this.dataLoading = true;
    this.loadItem();
  },
  validations: {
    proxyPackage: {
      title: {
        required,
        maxLength: maxLength(255),
      },
      source: {
        maxLength: maxLength(255),
      },
      login: {
        maxLength: maxLength(64),
      },
      password: {
        maxLength: maxLength(64),
      },
      price: {
        decimal,
        maxLength: maxLength(255),
      },
    },
  },
};
</script>
