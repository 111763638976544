<template>
  <v-card>
    <v-card-title
      v-if="item"
      class="headline primary lighten-1 white--text justify-space-between"
    >
      ID {{ item.id }}: {{ item.title }}
      <v-btn icon color="white" @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <div class="d-flex justify-center pa-10" v-if="dataLoading">
        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
      <div v-if="!dataLoading" class="pt-3">
        <div v-if="itemDetails.description" class="pa-4">
          {{ itemDetails.description }}
        </div>
        <v-row>
          <v-col>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>Login:</v-list-item-title>
                <v-list-item-subtitle>
                  {{ itemDetails.login }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>Password:</v-list-item-title>
                <v-list-item-subtitle>
                  {{ itemDetails.password }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>Source:</v-list-item-title>
                <v-list-item-subtitle>
                  {{ itemDetails.source }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line v-if="itemDetails.price">
              <v-list-item-content>
                <v-list-item-title>Price:</v-list-item-title>
                <v-list-item-subtitle>
                  {{ itemDetails.price }} {{ itemDetails.currency }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row>
          <v-col>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  Status:
                  <v-chip
                    class="ma-2"
                    :color="getStatusColor(itemDetails.status)"
                    outlined
                    small
                    dark
                  >
                    {{ itemDetails.status }}
                  </v-chip>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>Expires at:</v-list-item-title>
                <v-list-item-subtitle>
                  {{ itemDetails.expiredAt }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>Created at:</v-list-item-title>
                <v-list-item-subtitle>
                  {{ itemDetails.createdAt }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>Updated at:</v-list-item-title>
                <v-list-item-subtitle>
                  {{ itemDetails.updatedAt }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
      </div>
    </v-card-text>
    <v-divider></v-divider>
  </v-card>
</template>

<script>
import { FETCH_PACKAGE } from "@/store/actions/proxy-package";

import _ from "lodash";

export default {
  name: "ProxyPackageView",
  data() {
    return {
      dataLoading: true,
    };
  },
  props: {
    initialItem: Object,
  },
  computed: {
    itemDetails: function () {
      return this.$store.getters["proxyPackage/getItemDetails"]({
        key: "ID_" + this.item.id,
        defaultValue: this.item,
      });
    },
    itemLoaded: function () {
      return !_.isEmpty(this.itemDetails);
    },
    item: function () {
      return this.initialItem;
    },
  },
  watch: {
    item: {
      handler: function () {
        this.loadItem();
      },
      deep: true,
    },
  },
  methods: {
    loadItem: function () {
      this.dataLoading = true;
      const { item } = this;
      this.$store
        .dispatch("proxyPackage/" + FETCH_PACKAGE, { item })
        .then(
          () => {
            this.dataLoading = false;
          },
          (err) => {
            console.log("Err", err);
          }
        )
        .catch(() => {});
    },
    getStatusColor: function (status) {
      return this.$store.getters["proxyPackage/getColorByStatus"]({
        status,
        defaultValue: "default",
      });
    },
  },
  mounted() {
    this.loadItem();
  },
};
</script>
