<template>
  <v-card>
    <v-card-title
      class="headline primary lighten-1 white--text justify-space-between"
    >
      {{ $t("filters") }}
      <v-btn icon color="white" @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text class="mt-2">
      <v-row>
        <v-col cols="2">
          <v-text-field
            name="id"
            :label="$t('proxyPackages.attribute.id')"
            v-model="search.id"
            type="text"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            name="title"
            :label="$t('proxyPackages.attribute.title')"
            v-model="search.title"
            type="text"
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            name="description"
            :label="$t('proxyPackages.attribute.description')"
            v-model="search.description"
            type="text"
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            name="proxiesAmount"
            :label="$t('proxyPackages.attribute.proxiesAmount')"
            v-model="search.proxiesAmount"
            type="text"
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-autocomplete
            :clearable="true"
            name="source"
            :label="$t('proxyPackages.attribute.source')"
            v-model="search.source"
            :items="sources"
            :loading="isSourceLoading"
            :search-input.sync="sourceSearch"
            hide-no-data
            hide-selected
            item-text="source"
            item-value="source"
            :placeholder="$t('startTyping')"
          ></v-autocomplete>
        </v-col>
        <v-col cols="3">
          <v-select
            :clearable="true"
            :items="availableStatuses"
            :label="$t('proxyPackages.attribute.status')"
            v-model="search.status"
          ></v-select>
        </v-col>
        <v-col cols="3">
          <v-menu
            v-model="modal"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="100px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :clearable="true"
                v-model="search.expiredAt"
                :label="$t('proxyPackages.attribute.expiredAt')"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              no-title
              v-model="search.expiredAt"
              @input="modal = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="grey" text @click="$emit('close')">Close</v-btn>
      <v-btn color="blue darken-1" text @click="submit">Apply filters</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { FETCH_SOURCE_META } from "@/store/actions/proxy-package";

import { mapGetters } from "vuex";
import { emptyFilters } from "@/store/modules/proxy-package/state";

const emptyFiltersObj = {};
Object.assign(emptyFiltersObj, emptyFilters);

export default {
  name: "ProxyPackagesSearch",
  props: {
    labels: Object,
  },
  data() {
    return {
      attributeLabels: this.labels,
      modal: false,
      isSourceLoading: false,
      sourceSearch: "",
      sources: [],
      search: {},
    };
  },
  computed: {
    ...mapGetters("proxyPackage", ["filters", "availableStatuses"]),
  },
  watch: {
    sourceSearch: function (value) {
      this.isSourceLoading = true;
      this.$store
        .dispatch("proxyPackage/" + FETCH_SOURCE_META, { source: value })
        .then(
          (payload) => {
            this.isSourceLoading = false;
            this.sources = payload.data;
          },
          (err) => {
            console.log("Err", err);
          }
        )
        .catch(() => {});
    },
  },
  methods: {
    submit: function () {
      let filters = {};
      Object.assign(filters, this.search);
      this.$emit("applyFilters", { filters });
    },
  },
  created() {
    Object.assign(this.search, this.filters);
  },
};
</script>
