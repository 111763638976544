var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"mt-1",attrs:{"outlined":"","color":_vm.itemClass}},[_c('v-card-text',{staticClass:"pa-2 pl-4"},[_c('v-row',[_c('v-col',{staticClass:"d-flex flex-nowrap text-truncate",attrs:{"sm":"4","md":"4","lg":"2"}},[_vm._v(" "+_vm._s(_vm.proxyPackage.title)+" ")]),_c('v-col',{staticClass:"d-flex flex-nowrap text-truncate",attrs:{"sm":"8","md":"8","lg":"1"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"d-inline-block text-truncate",staticStyle:{"max-width":"100%"}},'div',attrs,false),on),[_vm._v(" "+_vm._s(_vm.proxyPackage.description)+" ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.proxyPackage.description))])])],1),_c('v-col',{attrs:{"sm":"4","md":"2","lg":"1"}},[(_vm.proxyPackage.price)?_c('span',[_vm._v(" "+_vm._s(_vm.proxyPackage.price)+" "+_vm._s(_vm.proxyPackage.currency)+" ")]):_vm._e(),(!_vm.proxyPackage.price)?_c('span',{staticClass:"d-lg-none grey--text caption"},[_vm._v(" Price not set ")]):_vm._e()]),_c('v-col',{staticClass:"d-flex flex-nowrap text-truncate",attrs:{"sm":"4","md":"1","lg":"1"}},[(_vm.proxyPackage.proxiesAmount)?_c('router-link',{staticClass:"grey--text text--darken-4 text-decoration-none",attrs:{"to":{
            name: 'ProxyList',
            query: { proxyPackageId: _vm.item.id },
          }}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-arrow-decision-outline")]),_vm._v(" "+_vm._s(_vm.proxyPackage.proxiesAmount)+" ")],1):_vm._e()],1),_c('v-col',{attrs:{"sm":"3","md":"3","lg":"1"}},_vm._l((_vm.proxyPackage.statistics),function(statisticItem,i){return _c('div',{key:i},[_c('router-link',{staticClass:"text-decoration-none grey--text text--darken-4",attrs:{"to":{
              name: 'AccountList',
              query: {
                proxyPackages: [statisticItem.proxyPackageId],
                target: [statisticItem.name],
                selectedFilters: ['proxyPackages', 'target'],
              },
            }}},[_c('v-icon',{domProps:{"textContent":_vm._s(
                'mdi-' +
                (statisticItem.name === 'vkontakte'
                  ? 'alpha-b-box'
                  : statisticItem.name)
              )}}),_c('span',{staticClass:"pl-1",domProps:{"textContent":_vm._s(statisticItem.value)}})],1)],1)}),0),_c('v-col',{staticClass:"d-flex flex-nowrap",attrs:{"sm":"4","md":"2","lg":"2"}},[_vm._v(" "+_vm._s(_vm.proxyPackage.source)+" ")]),_c('v-col',{staticClass:"d-flex flex-nowrap text-truncate",attrs:{"sm":"4","md":"2","lg":"1"}},[_c('v-chip',{attrs:{"color":_vm.getColorByStatus({ status: _vm.proxyPackage.status }),"outlined":"","small":"","dark":""}},[_vm._v(" "+_vm._s(_vm.proxyPackage.status)+" ")])],1),_c('v-col',{staticClass:"d-flex flex-nowrap text-truncate",attrs:{"sm":"5","md":"3","lg":"2"}},[_vm._v(" "+_vm._s(_vm.proxyPackage.expiredAt)+" "),(!_vm.proxyPackage.expiredAt)?_c('span',{staticClass:"d-lg-none grey--text caption"},[_vm._v(" Date of expiration not set ")]):_vm._e()]),_c('v-col',{staticClass:"text-end",attrs:{"sm":"3","md":"2","lg":"1"}},[(_vm.proxyPackage.status === 'inactive')?_c('v-btn',{attrs:{"x-small":"","icon":"","color":"primary"},on:{"click":function($event){return _vm.$emit('activateItem')}}},[_c('v-icon',[_vm._v("mdi-play-speed")])],1):_vm._e(),_c('v-btn',{attrs:{"x-small":"","icon":"","color":"primary"},on:{"click":function($event){return _vm.$emit('editInfo')}}},[_c('v-icon',[_vm._v("mdi-pencil-outline")])],1),_c('v-btn',{attrs:{"x-small":"","icon":"","color":"primary"},on:{"click":function($event){return _vm.$emit('viewInfo')}}},[_c('v-icon',[_vm._v("mdi-eye-outline")])],1),_c('v-btn',{attrs:{"x-small":"","icon":"","color":"primary"},on:{"click":function($event){return _vm.$emit('syncItem')}}},[_c('v-icon',[_vm._v("mdi-sync")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }